import React from "react";
import _ from "lodash";
import { Layout } from "../components/index";
import { getPages, safePrefix } from "../utils";
import Helmet from "react-helmet";
import data from "../data/niepublicznaPoradniaPsychPed.json";
import { FaqBlock } from "../components";
import CtaBlock from "../components/CtaBlock";
import styled, { css } from "styled-components/macro";
import FeaturesList from "../components/FeaturesList";
import { getFeaturesByCategory } from "../utils/getFeaturesByCategory";

export default class PoradnieNiepublicznaPoradniaPsychPed extends React.Component {
  render() {
    const ctaBlockData = _.get(this.props, "pageContext.pages")
      .find((page) => page?.url === "/")
      .frontmatter?.sections?.find((section) => section.type === "ctablock");
    return (
      <Layout {...this.props}>
        <Helmet>
          <title>
            {_.get(this.props, "pageContext.frontmatter.title_seo") &&
              _.get(this.props, "pageContext.frontmatter.title_seo") + " - "}
            {_.get(this.props, "pageContext.site.siteMetadata.title")}
          </title>
        </Helmet>
        <Hero {...this.props} />

        <FeaturesPedagogical {...this.props} />
        {ctaBlockData && <CtaBlock section={ctaBlockData} />}
        <FaqBlock
          section={{
            title: data.faq.title,
            subtitle: data.faq.subtitle,
            faqitems: data.faq.items,
          }}
        />
      </Layout>
    );
  }
}

const Hero = (props) => {
  return (
    <section id="features" className="block text-block bg-gray outer">
      <div className="inner">
        <div className="grid">
          <div className="cell block-content">
            <h1 className="block-title underline">
              {_.get(props, "pageContext.frontmatter.title")}
            </h1>
            <div className="block-copy">
              <p>
                Zapraszamy dzieci i młodzież, które potrzebują wsparcia w
                zakresie:
              </p>
              <ul>
                <li>rozwoju umiejętności szkolnych,</li>
                <li>poradzenia sobie z trudnościami społecznymi,</li>
                <li>poradzenia sobie z trudnościami emocjonalnymi,</li>
              </ul>
              <p>
                Do poradni psychologiczno-pedagogicznej „Dobra Przestrzeń”
                zapraszamy również rodziców i opiekunów, szukających wsparcia i
                inspiracji w procesie wychowawczym.
              </p>
            </div>
            <p className="block-buttons">
              <a className="button" href="/kontakt">
                {data.hero.buttonPrimary}
              </a>
            </p>
          </div>
          <div className="cell block-preview">
            <img
              src={safePrefix(_.get(props, "pageContext.frontmatter.img_path"))}
              alt={_.get(props, "pageContext.frontmatter.title_seo")}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

const FeaturesPedagogical = (props) => {
  let displayPosts = _.orderBy(
    getPages(props.pageContext.pages, "/oferta").filter(
      (page) => page.name !== "index"
    ),
    "frontmatter.date",
    "desc"
  ).filter(
    (post) =>
      post &&
      post.frontmatter &&
      post.frontmatter.tags &&
      post.frontmatter.tags.includes(
        "Niepubliczna poradnia psychologiczno-pedagogiczna"
      )
  );
  console.log({ displayPosts });
  if (!displayPosts) {
    return null;
  }

  const featuresByCategory = Object.fromEntries(
    Object.entries(getFeaturesByCategory(displayPosts)).filter(
      ([, value]) => !value || value.length > 0
    )
  );
  return (
    <div className="outer">
      {Object.entries(featuresByCategory).map(([category, posts], index) => {
        return (
          <SpacedDiv className="inner" key={index}>
            <h2
              className="post-subtitle"
              css={css`
                border-bottom: none;
                font-size: 2rem;
                font-weight: bold;
              `}
            >
              {category}
            </h2>
            <FeaturesList posts={posts} />
          </SpacedDiv>
        );
      })}
    </div>
  );
};

const SpacedDiv = styled.div`
  margin-bottom: 6rem;
`;
